const getDocPath = (doc) => (doc.ref && doc.ref.path) || doc.path;

const getDataFromDocument = (doc) => {
  const data = {
    id: doc.id,
    path: getDocPath(doc),
    ...doc.data(),
  };
  return data;
};

export default getDataFromDocument;
