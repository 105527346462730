import { useCallback, useState } from "react";
import { isFunction } from "lodash";
import useLocalStorage from "./useLocalStorage";

type StateUpdate<S> = (prev: S) => S;

type StateOrUpdate<S> = S | StateUpdate<S>;

type SetState<S> = (value: StateOrUpdate<S>) => void;

type HookReturnValue<S> = [S, SetState<S>];

export default function usePersistentState<S>(
  initialValue: S,
  key: string
): HookReturnValue<S> {
  const { getItem, setItem } = useLocalStorage();

  const getInitialValue = () => {
    const item = getItem(key, initialValue);
    setItem(key, item);
    return item;
  };

  const [state, setState] = useState(getInitialValue);

  const setAndPersistState = useCallback(
    (value: StateOrUpdate<S>) => {
      const valueToSet = isFunction(value) ? value(getItem(key)) : value;
      setItem(key, valueToSet);
      setState(value);
    },
    [key, getItem, setItem]
  );

  return [state, setAndPersistState];
}
