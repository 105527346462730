import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import routes from "../routes";
import Layout from "./Layout";
import useRouter from "../hooks/useRouter";
import useSupportProfile from "../hooks/useSupportProfile";
import isSupportAccount from "../utils/isSupportAccount";

export default function Redirect() {
  const { destination } = useParams();
  const { routeTo } = useRouter();
  const supportProfile = useSupportProfile();

  useLayoutEffect(() => {
    if (destination === "dashboard" && isSupportAccount(supportProfile)) {
      routeTo(routes.support.dashboard);
    } else if (destination === "dashboard") {
      routeTo(routes.dashboard);
    }
  }, [destination, supportProfile, routeTo]);

  return <Layout />;
}
