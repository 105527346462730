import { useEffect, useState } from "react";
import { get, includes, noop, uniq, without } from "lodash";

const DEFAULT_OPTIONS = {
  multi: false,
  onChange: noop,
};

export default function useFormInputState(
  initialValue,
  options = DEFAULT_OPTIONS
) {
  const [value, setValue] = useState(initialValue);

  const performChange = (newValue) => {
    setValue(newValue);
  };

  const onChange = (e) => {
    const { target } = e;
    if (options.multi) {
      if (includes(value, target.value)) {
        performChange(without(value, target.value));
      } else {
        performChange(uniq([...value, target.value]));
      }
    } else {
      performChange(get(e, "target.value", e));
    }
  };

  useEffect(() => setValue(initialValue), [initialValue]);

  return [value, onChange];
}
