import React from "react";
import { Spinner } from "reactstrap";

interface Props {
  children: React.ReactNode;
}

export default function BusyMessage({ children }: Props) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>{children}</div>
      <div className="flex-shrink-0">
        <Spinner />
      </div>
    </div>
  );
}
