import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../Layout";

const LayoutContainer = ({ children }) => (
  <Layout>
    <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center">
      <Container className="mt-5">
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="my-5 rounded p-3 shadow-lg">{children}</div>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

const LayoutFooter = ({ children }) => (
  <div className="pt-3 d-flex justify-content-between align-items-center">
    {children}
  </div>
);

export default {
  Container: LayoutContainer,
  Footer: LayoutFooter,
};
