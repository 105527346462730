import React from "react";
import { Route } from "react-router";

const QUERYSTRING = "(\\?.*)?";

// A wrapper for <Route> that redirects to the sign
// in screen if the user is not yet authenticated.
export default function RouteWithQueryString({ path, ...rest }) {
  console.log(`RouteWithQueryString[path=${path}]`);
  const pathWithQuerystring = path + QUERYSTRING;
  return <Route {...rest} path={pathWithQuerystring} />;
}
