import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

export function useScrollToTop() {
  return () => {
    // Always restore scoll to the top of the page
    window.scrollTo(0, 0);
  };
}

export default function ScrollRestoration() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Always restore scoll to the top of the page
    window.scrollTo(0, 0);

    // If a #hash is provided, smooth scroll to it
    if (!isEmpty(hash)) {
      const elementId = hash.substr(1);
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [pathname, hash]);

  return null;
}
