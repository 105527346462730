import { useMemo } from "react";
import { useLocation } from "react-router";

export default function useQuery() {
  const location = useLocation();
  const search = location.search;
  const result = useMemo(() => {
    const params = new URLSearchParams(search);
    const query = {
      passcode: params.get("passcode") || "",
    };
    console.log(search, query);
    return query;
  }, [search]);
  return result;
}
