import React, { useState, Fragment } from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from "reactstrap";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiCalendar, mdiClockOutline } from "@mdi/js";
import { isNaN } from "lodash";

import IfDevelopment from "./helpers/IfDevelopment";
import classes from "../utils/classes";
import parseDate from "../utils/parseDate";
import parseTime from "../utils/parseTime";
import formatDate from "../utils/formatDate";
import formatTime from "../utils/formatTime";

const USE_INPUT_TYPE = true;
const USE_DEBUG = false;

export default function DateTime(props) {
  const { value, onChange } = props;

  const dateValue = formatDate(value);
  const timeValue = formatTime(value);

  const [tempDate, setDate] = useState(dateValue);
  const [tempTime, setTime] = useState(timeValue);

  const performChange = (d, t) => {
    const newDate = parseDate(d);
    const newTime = parseTime(t);

    const newValue = moment(value).isValid() ? moment(value) : moment();
    newValue.set(newTime);
    newValue.set(newDate);

    const timestamp = newValue.unix() * 1000;
    onChange({ target: { value: isNaN(timestamp) ? null : timestamp } });
  };

  const onChangeDate = (e) => {
    setDate(e.target.value);
    performChange(e.target.value, tempTime);
  };

  const onChangeTime = (e) => {
    setTime(e.target.value);
    performChange(tempDate, e.target.value);
  };

  return (
    <Fragment>
      <div className="d-md-flex">
        <div className="flex-grow-1 mb-3 mb-md-0 mr-md-3">
          <InputGroup
            className={classes({
              "border-danger": props.invalid,
            })}
          >
            <Input
              id={props.id}
              type={USE_INPUT_TYPE ? "date" : "text"}
              value={tempDate}
              onChange={onChangeDate}
              invalid={props.invalid}
              placeholder="YYYY-MM-DD"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <Icon path={mdiCalendar} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <FormText color={props.invalid ? "danger" : "muted"}>
            Format date as YYYY-MM-DD
          </FormText>
        </div>

        <div className="flex-grow-1">
          <InputGroup className={classes({ "border-danger": props.invalid })}>
            <Input
              type={USE_INPUT_TYPE ? "time" : "text"}
              value={tempTime}
              onChange={onChangeTime}
              invalid={props.invalid}
              placeholder="HH:MM"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <Icon path={mdiClockOutline} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <FormText color={props.invalid ? "danger" : "muted"}>
            Format time as HH:MM
          </FormText>
        </div>
      </div>
      <IfDevelopment and={USE_DEBUG}>
        <code>
          value: {value}
          <br />
          {moment(value).toString()}
          <br />
          dateValue: {dateValue} date: {tempDate}
          <br />
          timeValue: {timeValue} time: {tempTime}
        </code>
      </IfDevelopment>
    </Fragment>
  );
}
